<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="收支详细" width="700px" @closed="form = null; auditForm = null;">
    <template v-if="form">
      <el-form ref="form" :model="form" label-width="80px" label-position="right" label-suffix=":">
        <div class="h">
          <div style="width: 50%;">
            <form-info-item label="经销商">{{form.friendEntName}}</form-info-item>
            <form-info-item label="方向">{{form.inOutType === "in" ? '收入' : '支出'}}</form-info-item>
            <form-info-item label="支付方式">{{paymentMethods[form.payType]}}</form-info-item>
            <form-info-item label="支付日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
            <form-info-item label="收/支金额">
              <b :class="form.inOutType === 'in' ? 'fc-s' : 'fc-e'">{{$price(form.payMoney)}}</b>
            </form-info-item>

            <form-info-item label="手续费">
              <b>{{$price(form.fee)}}</b>
              <span class="fs-mini fc-g" v-if="form.fee > 0"><br />经销商承担{{$price(form.payFee)}}<br />供应商承担{{$price(form.fee - form.payFee)}}</span>
            </form-info-item>
            
            <form-info-item label="使用余额">
              <b :class="form.inOutType === 'in' ? 'fc-s' : 'fc-e'">{{$price(form.accountMoney)}}</b>
            </form-info-item>

            <form-info-item label="记账日期" v-if="form.status === 1">{{new Date(form.checkinTime).format('yyyy/MM/dd')}}</form-info-item>
            <form-info-item label="支付账户" v-if="form.payerAccount">{{form.payerAccount}}</form-info-item>
            <form-info-item label="收款账户" v-if="form.payeeAccount">{{form.payeeAccount}}</form-info-item>
            <form-info-item label="付款人" v-if="form.payType ===1 && form.relationFormType===3">{{form.payer}}</form-info-item>
            <form-info-item label="付款日期" v-if="form.payType ===1 && form.relationFormType===3">{{form.offlinePayTime?new Date(form.offlinePayTime).format('yyyy/MM/dd'):''}}</form-info-item>
            
          </div>
          <div class="flex">
            <form-info-item label="收支单号">{{form.code}}</form-info-item>
            <form-info-item label="销售单号" v-if="form.relationFormType === 0">{{form.relationFormCode}}</form-info-item>
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <template v-if="form.status !== 0">
              <form-info-item label="审批时间">{{new Date(form.checkTime).format()}}</form-info-item>
              <form-info-item label="审批意见">{{form.checkInfo}}</form-info-item>
            </template>
            <template v-if="form.payType ===1 && form.relationFormType===3">
                <form-info-item label="付款回单">
                  <file-uploader :entity-id="form.fileId" folder="pay/receipt" multiple readonly empty-text="没有上传付款回单" />
                </form-info-item>
                <form-info-item label="授权书">
                  <file-uploader :entity-id="form.fileId" folder="pay/auth" multiple readonly empty-text="没有上传授权书" />
                </form-info-item>
              </template>
          </div>
        </div>

        <form-info-item label="备注">{{form.info}}</form-info-item>
      </el-form>
      <template v-if="auditable && auditForm">
        <el-divider content-position="left">审批</el-divider>
        <el-form ref="form" :model="auditForm" label-width="78px" label-position="right" label-suffix=":">
          <div class="h">
            <el-form-item label="是否通过" style="width: 50%;">
              <el-radio-group v-model="auditForm.pass" @change="handleAuditPassChange">
                <el-radio-button :label="true">通过</el-radio-button>
                <el-radio-button :label="false">不通过</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="记账日期" class="flex" label-width="106px" v-if="auditForm.pass">
              <el-date-picker type="date" v-model="auditForm.checkinTime" value-format="timestamp" :clearable="false" style="width:100%;" />
            </el-form-item>
          </div>
          <el-form-item label="审批意见">
            <el-input type="textarea" v-model="auditForm.checkInfo" :maxlength="120" :rows="3" resize="none" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="auditing" @click="doAudit">提交审批</el-button>
            <el-button @click="dialog = false">取消</el-button>
          </el-form-item>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>
      
      
<script>
import { audit } from "@/api/payForm";

export default {
  data() {
    return {
      saving: false,
      auditing: false,
      dialog: false,
      auditable: false,
      form: null,
      auditForm: null,
      paymentMethods: ["在线支付", "线下支付"],
      status: [
        {
          type: "info",
          label: "待审批"
        },
        {
          type: "success",
          label: "已通过"
        },
        {
          type: "danger",
          label: "未通过"
        }
      ]
    };
  },
  methods: {
    doAudit() {
      this.$confirm(
        `确定对该收款信息进行【${
          this.auditForm.pass ? "通过" : "不通过"
        }】审批吗？`,
        "操作提示",
        {
          type: "warning"
        }
      ).then(_ => {
        this.auditing = true;
        audit(this.auditForm)
          .then(res => {
            this.$notify({
              title: "审批成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.auditing = false;
          });
      });
    },
    resetForm(form, auditable = false) {
      if (form) {
        this.auditable = form && form.id && auditable;
        this.form = form;
        if (this.auditable) {
          this.auditForm = {
            id: form.id,
            pass: true,
            checkinTime: this.form.payTime,
            checkInfo: ""
          };
        }
        this.dialog = true;
      }
    },

    handleAuditPassChange() {
      if (this.form && this.auditForm) {
        this.auditForm.checkinTime = this.auditForm.pass
          ? this.form.payTime
          : null;
      }
    }
  }
};
</script>